import React from "react"
import { Accordion, Card, Button, Badge, Alert } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Date from "../components/date"

const ReadingMaterial = () => (
  <React.Fragment>
    <Layout pageInfo={{ pageName: "Reading Material" }}>
      <SEO title="Reading Material" />

      <div>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Misc.</Accordion.Header>
            <Accordion.Body>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/misc/Enviromental.Ethics.pdf"
                >
                  Enviromental Ethics <Badge bg="secondary">PDF</Badge>
                </Button>

                {"   "}

                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/misc/Enviromental.Ethics.pptx"
                >
                  Enviromental Ethics <Badge bg="secondary">PPTX</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/misc/EEbSD.pdf"
                >
                  Envirnmental Ethics behind Sustainable Development{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/misc/Poly.Acid.pdf"
                >
                  Isopoly & Heteropoly anion{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/misc/Core.Paper.MOT.Question.Bank.pdf"
                >
                  Core Paper MOT Question Bank{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/misc/Core.Paper.X-Ray.Technique.pdf"
                >
                  Core Paper X-Ray Technique{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/misc/Research.Methodology.Lecture.Note.pdf"
                >
                  Research Methodology Lecture Note{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/misc/ChemistryInEnvironment.pdf"
                >
                  Importance of Chemistry in Environment<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <br></br>
      <h4>Post Graduate</h4>
      <div>
        <Accordion>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Semester I</Accordion.Header>
            <Accordion.Body>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/I/Vilsmeir.Reaction.pdf"
                >
                  Vilsmeir reaction <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/I/Gattermann-Koch.Reaction.pdf"
                >
                  Gattermann-Koch Reaction <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/I/Diazonium.Coupling.pdf"
                >
                  Diazonium Coupling <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/I/Aromatic.Electrophilic.Substitution.pdf"
                >
                  Aromatic Electrophilic Substitution{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/I/Aromatic.Electrophilic.Substitution-2.pdf"
                >
                  Aromatic Electrophilic Substitution II{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/I/Microstate.and.Term.Symbol.pdf"
                >
                  Microstate & Term symbol <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/I/Orgel.Diagram.pdf"
                >
                  Orgel Diagram <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/I/BentsRule.pdf"
                >
                  Bents Rule <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Semester II</Accordion.Header>
            <Accordion.Body>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/II/Racah.Parameter.pdf"
                >
                  Racah Parameter <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/II/Nephelauxtic effect.pdf"
                >
                  Nephelauxtic Effect <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/II/Charge transfer spectra of CCC.pdf"
                >
                  Charge transfer spectra of CCC{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/II/Selection.Rule.pdf"
                >
                  Selection Rule <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/II/Tanabe Sugano Diagram I.pdf"
                >
                  Tanabe Sugano Diagram I <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/II/Tanabe Sugano Diagram II.pdf"
                >
                  Tanabe Sugano Diagram II <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/II/Calculation of Tanabe Sugano.pdf"
                >
                  Calculation of Tanabe Sugano
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Semester III</Accordion.Header>
            <Accordion.Body>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/UV.Spectroscopy.pdf"
                >
                  UV Spectroscopy <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/IR.Spectroscopy.pdf"
                >
                  IR Spectroscopy <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/NMR.Spectroscopy.pdf"
                >
                  NMR Spectroscopy <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/Mass.Spectroscopy.pdf"
                >
                  Mass Spectroscopy <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/GreenChemistry1.pdf"
                >
                  Green Chemistry - I <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/GreenChemistry2.pdf"
                >
                  Green Chemistry - II <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/Qualitative analysis of given salt mixture.pdf"
                >
                  Qualitative analysis of given salt mixture<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/Qualitative analysis containing interfere radicals.pdf"
                >
                  Qualitative analysis containing interfere radicals<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/Introduction to Analytical Chemistry.pdf"
                >
                  Introduction to Analytical Chemistry<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Semester IV</Accordion.Header>
            <Accordion.Body>
              <p>


                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/ArylCarbeneAlkylideneCarbyneLigands.pdf"
                >
                  Aryl, Carbene, Alkylidene and Carbyne Ligands.pdf <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/TransitionMetalOrganometallicChemistry.pdf"
                >
                  Transition Metal Organometallic Chemistry <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/MetalAlkylAryl.pdf"
                >
                  Metal alkyl or aryl <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Terpenoids.pdf"
                >
                  Terpenoids <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Terpenoids-2.pdf"
                >
                  Terpenoids II <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/ETR.402.I.pdf"
                >
                  Electron Transfer Reaction in Oh Complexes{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Marcus.Hush.Theory.pdf"
                >
                  Marcus Hush Theory <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Supramolecular.Chemistry.pdf"
                >
                  Supramolecular Chemistry <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Metal.Alkoxide.pdf"
                >
                  Metal Alkoxide <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Basic.Concepts.Supramolecular.Chemistry.pdf"
                >
                  Basic concepts of Supramolecular Chemistry{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Synthesis.Preparation.and.Properties.of.Metal.Acetylacetonate.Complex.pdf"
                >
                  Synthesis, preparation & properties of Metal
                  Acetylacetonate complex <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Reaction.Mechanism.Oh.Complex.pdf"
                >
                  Reaction Mechanism of Oh Complexes{" "}
                  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Photochemistry.pdf"
                >
                  Photochemistry <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Photochemistry-II.pdf"
                >
                  Photochemistry II<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Zinziberene.pdf"
                >
                  Zinziberene <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/PhotochemistryMetalComplexes.pdf"
                >
                  Photo Chemistry of Metal Complexes <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/JablonskiDiagram.pdf"
                >
                  Jablonski Diagram &amps; Kasha&apos;s Rule (Photo Chemistry) <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/SchiffBaseSyntheticMethods.pdf"
                >
                  Schiff Base and their Synthetic Methods <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/GreenChemistry1.pdf"
                >
                  Green Chemistry - I <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/GreenChemistry2.pdf"
                >
                  Green Chemistry - II <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/Qualitative analysis of given salt mixture.pdf"
                >
                  Qualitative analysis of given salt mixture<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/Qualitative analysis containing interfere radicals.pdf"
                >
                  Qualitative analysis containing interfere radicals<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/Introduction to Analytical Chemistry.pdf"
                >
                  Introduction to Analytical Chemistry<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>



            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <br></br>
      <h4>Ph.D.</h4>
      <div>
        <Accordion>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Ph.D. Coursework</Accordion.Header>
            <Accordion.Body>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/SocioWorkshop.pdf"
                >
                  Research and Its Importance  <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/ResearchImportance.pdf"
                >
                  Research and Importance of Research. <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/Research.Hypothesis.pdf"
                >
                  Research Hypothesis <Badge bg="secondary">PDF</Badge>
                </Button>
                {"   "}
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/Research.Hypothesis.pptx"
                >
                  Research Hypothesis <Badge bg="secondary">PPTX</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Synthesis.Preparation.and.Properties.of.Metal.Acetylacetonate.Complex.pdf"
                >
                  Synthesis, preparation & properties of Metal
                  Acetylacetonate complex <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/UV.Spectroscopy.pdf"
                >
                  UV Spectroscopy <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/IR.Spectroscopy.pdf"
                >
                  IR Spectroscopy <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/NMR.Spectroscopy.pdf"
                >
                  NMR Spectroscopy <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/III/Mass.Spectroscopy.pdf"
                >
                  Mass Spectroscopy <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Photochemistry.pdf"
                >
                  Photochemistry <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>

              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/IV/Photochemistry-II.pdf"
                >
                  Photochemistry II <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/ScientificApproach.pdf"
                >
                  Science and Scientific Approach <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/SchiffBaseSyntheticMethods.pdf"
                >
                  Schiff Base and their Synthetic Methods <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/IPR.pdf"
                >
                  Intellectual Property Rights <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/Plagiarism.pdf"
                >
                  Plagiarism <Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/BibAndReferences.pdf"
                >
                  Bibliography and References<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              <p>
                <Button
                  variant="outline-dark"
                  size="sm"
                  href="../documents/reading-material/Ph.D/StatisticalData.pdf"
                >
                  Statistical data in Research Methodology<Badge bg="secondary">PDF</Badge>
                </Button>
              </p>
              
              
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <br></br>

      <hr />
    </Layout>
  </React.Fragment>
)

export default ReadingMaterial
